import logo from "@assets/images/turei_logo.png";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { useState } from "react";





import config from "@data/config.json";

interface Props {
  pages: NavItem[];
}

const Header = ({ pages }: Props) => {
  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  return (
    <div>
      <div
        className={`max-md:hidden grid grid-cols-3 bg-primary place-items-center h-12 px-4 ${
          config.showBanner ? "" : "hidden"
        }`}
      >
        <div></div>
        <h1 className=" text-white">Servicing the Auckland Region</h1>
        <div className="flex justify-end gap-6 w-full">
          <a
            className="text-white hover:text-secondary transition-colors"
            href={`tel:${config.phone}`}
          >
            {config.phone}
          </a>
          <a
            href={`mailto:${config.email}`}
            className="text-white hover:text-secondary transition-colors"
          >
            {`${config.email}`}
          </a>
        </div>
      </div>

      <div className="flex justify-between items-center max-md:flex-col gap-6 p-4">
        <div className="flex justify-between max-md:w-full items-center">
          <div className="flex justify-center items-center gap-x-2">

          
          <a href="/" className="btn btn-ghost h-fit">
            <img src={logo.src} alt="logo" width={250} className="w-16 p-1" />
         
            <h1 className="text-3xl max-md:text-4xl text-black font-robot font-normal" >
              T & Ko 
            </h1>
          </a>
          </div>

          {!showNav ? (
            <AiOutlineMenu
              className="max-md:block hidden text-5xl cursor-pointer"
              onClick={toggleNav}
            />
          ) : (
            <AiOutlineClose
              className="max-md:block hidden text-5xl cursor-pointer"
              onClick={toggleNav}
            />
          )}
        </div>
        <div
          className={`navbar w-fit bg-base-100 justify-end ${
            showNav ? "max-md:block" : "max-md:hidden"
          }`}
        >
          <ul
            className={`menu menu-horizontal px-1 gap-6 max-md:w-full text-xl max-md:flex-col`}
          >
            {pages.map((page, index) => (
              <li
                key={index}
                className={
                  page.name === "Join Today"
                    ? "bg-secondary text-white rounded-md"
                    : ""
                }
              >
                <a href={page.url}>{page.name}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
